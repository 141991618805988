<template>
  <div class="container content">
    <div class="headline">
      <h1>{{ $t('message.education-partner.header') }}</h1>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.partner.instructions.header') }}</p>
          <div v-html="$t('message.partner.instructions.txt')"></div>
        </article>
     </div>
      <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.education-partner.show.header') }}</p>
          <div class="columns">
           <div class="column is-half">
             <b-field :label="$t('message.education.add.form.name')">
                  <b-input v-model="editEducationPartner.name"></b-input>
              </b-field>
               <b-field :label="$t('message.education-partner.show.name')">
                 <b-input v-model="editEducationPartner.name"></b-input>
              </b-field>
              <!--b-field :label="$t('message.education-partner.show.description')">
                {{ educationPartner.description }}
              </b-field-->
              <b-field :label="$t('message.education-partner.show.email')">
                <b-input v-model="editEducationPartner.email"></b-input>
              </b-field>
              <b-field :label="$t('message.education-partner.show.mobile')">
                <b-input v-model="editEducationPartner.mobile"></b-input>
              </b-field>
              <b-field :label="$t('message.education-partner.show.address')">
                <b-input v-model="editEducationPartner.address"></b-input>
              </b-field>
              <b-field :label="$t('message.education-partner.show.zip')">
                <b-input v-model="editEducationPartner.zip"></b-input>
              </b-field>
              <b-field :label="$t('message.education-partner.show.city')">
                <b-input v-model="editEducationPartner.city"></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field :label="$t('message.education-partner.show.vat')">
                {{ editEducationPartner.vat }}
              </b-field>
              <b-field :label="$t('message.education-partner.show.www')">
                <b-input v-model="editEducationPartner.www"></b-input>
              </b-field>
             </div>
            </div>

            <b-button
              :loading="false"
              :label="$t('message.education-partner.save.button')"
              size="is-large"
              class="fullwidth"
              type="is-success" 
              v-on:click="save" />

        </article>
     </div>
    </div>  
  </div>
</template>

<script>
export default {
    data() {
        return {
          editEducationPartner: {
            name: '',
            address: '',
            email: '',
            mobile: '',
            zip: '',
            city: '',
            vat: '',
            logo: '',
            www: '',
          }
        }
    },
    methods: {
        save() {
          this.$store.dispatch("editPartner", this.editEducationPartner).then((response) => {
              var status = response.status
              if (status >= 200 && status < 300) {
                  this.$router.push('/kumppani')
              } else {
                  this.toastFailure(this.$t('message.partner.toaster.company.creationError'));
              }
          }).catch((error) => {
              this.ERROR(error);
              this.toastFailure(this.$t('message.partner.toaster.company.creationError'));
          });
        },
          /*
          this.$store.dispatch("validatePartner", this.editEducationPartner).then((response) => {
              console.log(response)
              this.$store.dispatch("editPartner", this.editEducationPartner).then((response) => {
                  var status = response.status
                  if (status >= 200 && status < 300) {
                      this.$router.push('/kumppani')
                  } else {
                      this.toastFailure(this.$t('message.partner.toaster.company.creationError'));
                  }
              }).catch((error) => {
                  this.ERROR(error);
                  this.toastFailure(this.$t('message.partner.toaster.company.creationError'));
              });
          }).catch((error) => {
              var displayed = false
              var msg = error.response.data
              for (const _key in msg.errors) {
                  this.toastFailure(this.$t("message.error.education-partner." + _key));
                  displayed = true
              }
              if (!displayed) {
                  this.toastFailure(this.$t('message.partner.toaster.company.creationError'));
              }
              console.log(error, error.response)
          })
        },*/
    },
    computed: {
    },
    mounted() {
        var __id = this.$route.params.education_partner_id
        this.$store.dispatch("fetchPartners2").then((response) => {
          var partner = response.data.find(p => p.id == parseInt(__id))
          this.editEducationPartner = {...partner}
        });

    }
}
</script>